<template>
  <b-table-simple striped responsive class="mb-0">
    <b-thead>
      <b-tr>
        <b-th class="white-space-nowrap">Servizio</b-th>
        <b-th class="table-cell--compact white-space-nowrap text-right">Costo</b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <pricelist-table-row
        v-for="prod in newList"
        :key="prod.key"
        :item="prod"
        :request-type="requestType"
      />
    </b-tbody>
  </b-table-simple>
</template>

<script>

const PricelistTableRow = () => import('./PricelistTableRow.vue');

export default {
  name: 'PricelistTable',
  components: { PricelistTableRow },
  props: {
    list: Array,
    requestType: String,
  },
  data() {
    return {
      listItems: this.list || [],
    };
  },
  computed: {
    newList() {
      return this.listItems;
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>

</style>
